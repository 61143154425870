import { Controller } from "stimulus"
import $ from "jquery"
import DataTable from "datatables.net-dt"

const defaultOptions = {
  processing: true,
  serverSide: true,
  order: [],
  language: {
    sProcessing: "Traitement en cours...",
    sSearch: "Rechercher :",
    sLengthMenu: "Afficher _MENU_ éléments",
    sInfo: "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
    sInfoEmpty: "Affichage de l'élément 0 à 0 sur 0 élément",
    sInfoFiltered: "(filtré de _MAX_ éléments au total)",
    sInfoPostFix: "",
    sLoadingRecords: "Chargement en cours...",
    sZeroRecords: "Aucun élément à afficher",
    sEmptyTable: "Aucune donnée disponible dans le tableau",
    oAria: {
      sSortAscending: ": activer pour trier la colonne par ordre croissant",
      sSortDescending: ": activer pour trier la colonne par ordre décroissant"
    },
    searchPlaceholder: "Entrez un terme de recherche"
  },
  dom: '<"dt-layout-row"<"dt-layout-cell dt-start"i><"dt-layout-cell dt-end"f>><"dt-layout-row dt-layout-table"<"dt-layout-cell"t>><"dt-layout-row"<"dt-layout-cell dt-start"p><"dt-layout-cell dt-end"l>>', // eslint-disable-line 
  Type: "full_numbers",
  pageLength: 50,
}

export default class extends Controller {
  connect() {
    if ($("#organizations-datatable").length > 0)              this.organizationDT()               // eslint-disable-line no-multi-spaces
    else if ($("#member-datatable").length > 0)                this.memberDT()                     // eslint-disable-line no-multi-spaces
    else if ($("#quotes-datatable").length > 0)                this.quoteDT()                      // eslint-disable-line no-multi-spaces
    else if ($("#orders-datatable").length > 0)                this.orderDT()                      // eslint-disable-line no-multi-spaces
    else if ($("#invoices-datatable").length > 0)              this.invoiceDT()                    // eslint-disable-line no-multi-spaces
    else if ($("#expos-monuments-datatable").length > 0)       this.exposMonumentDT()              // eslint-disable-line no-multi-spaces
    else if ($("#consignment-stocks-datatable").length > 0)    this.consignmentStockDT()           // eslint-disable-line no-multi-spaces
    else if ($("#stocks-datatable").length > 0)                this.stockDT()                      // eslint-disable-line no-multi-spaces
    else if ($("#catalog-monument-datatable").length > 0)      this.catalogMonumentDT()            // eslint-disable-line no-multi-spaces
    else if ($("#monument-price-datatable").length > 0)        this.monumentPriceDT()              // eslint-disable-line no-multi-spaces
    else if ($("#accessory-price-datatable").length > 0)       this.accessoryPriceDT()             // eslint-disable-line no-multi-spaces
    else if ($("#frame-price-datatable").length > 0)           this.framePriceDT()                 // eslint-disable-line no-multi-spaces
    else if ($("#engraving-price-datatable").length > 0)       this.engravingPriceDT()             // eslint-disable-line no-multi-spaces
    else if ($("#sent-mails-datatable").length > 0)            this.sentMailsDT()                  // eslint-disable-line no-multi-spaces
  }

  organizationDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#organizations-datatable").data("source")
      },
      columns: [
        { data: "clientId" },
        { data: "contactName" },
        { data: "display_name" },
        { data: "email" },
        { data: "phone" },
        { data: "action" },
      ],
      columnDefs: [
        { targets: 5, orderable: false } // Disable ordering for the last column (Action)
      ],
    }

    // Init datatable
    new DataTable("#organizations-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (title === "Rep") {
              // Populate the select element with unique values from the orginal select
              createSelectInput(this, (select) => {
                document.querySelector("#contact_name").options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else {
              createTextInput(this)
            }
            return true
          })

        // show filter
        $("#organizations-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  memberDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#member-datatable").data("source")
      },
      columns: [
        { data: "id" },
        { data: "organizations" },
        { data: "nom" },
        { data: "email" },
        { data: "identifiant" },
        { data: "groups" },
        { data: "mobile" },
        { data: "phone" },
        { data: "actif" },
        { data: "dern" },
        { data: "action" },
      ],
      columnDefs: [
        { targets: 1, orderable: false, visible: false }, // organizations column
        { targets: 10, orderable: false } // action 'détail' && 'activer/desactiver'
      ]
    }

    new DataTable("#member-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (title === "Groupes") {
              // Populate the select element with unique values from the orginal select
              createSelectInput(this, (select) => {
                document.querySelector("#group_id").options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else {
              let inputStyle = null
              if (title === "#") inputStyle = "width: 50px;"
              if (title === "Télé. Portable" || title === "Télé. Fixe") inputStyle = "width: 110px;"
              createTextInput(this, inputStyle)
            }
            return true
          })

        // show filter
        $("#member-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  quoteDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#quotes-datatable").data("source")
      },
      columns: [
        { data: "client" },
        { data: "clientId" },
        { data: "folderId" },
        { data: "quoteId" },
        { data: "creationDate" },
        { data: "status" },
        { data: "amount" },
        { data: "action" }
      ],
      columnDefs: [
        { targets: 7, orderable: false } // action 'détail' && 'activer/desactiver'
      ]
    }

    const table = new DataTable("#quotes-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (title === "Statut") {
              // Populate the select element with unique values from the original select
              createSelectInput(this, (select) => {
                document.querySelector("#status").options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else if (title === "Date") {
              createDateInput(this)
            } else {
              let inputStyle = null
              if (title === "Date" || title === "N° devis" || title === "N° dossier" || title === "N° client") inputStyle = "width: 100px;"
              createTextInput(this, inputStyle)
            }
            return true
          })
        // show filter
        $("#quotes-datatable tfoot").css("display", "table-header-group")
      }

    })

    const self = this
    table.on("draw", function (e, settings) {
      self.exportFn(e, settings)
    })
  }

  orderDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#orders-datatable").data("source")
      },
      columns: [
        { data: "client" },
        { data: "clientId" },
        { data: "folderId" },
        { data: "orderId" },
        { data: "quoteId" },
        { data: "creationDate" },
        { data: "amount" },
        { data: "action" }
      ],
      columnDefs: [
        { targets: 7, orderable: false } // action 'détail' && 'activer/desactiver'
      ]
    }

    new DataTable("#orders-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (title === "Date") {
              createDateInput(this)
            } else {
              let inputStyle = null
              if (title === "N° commande" || title === "N° devis" || title === "N° dossier" || title === "N° client") inputStyle = "width: 100px;"
              createTextInput(this, inputStyle)
            }
            return true
          })
        // show filter
        $("#orders-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  invoiceDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#invoices-datatable").data("source")
      },
      columns: [
        { data: "client" },
        { data: "clientId" },
        { data: "folderId" },
        { data: "invoiceId" },
        { data: "orderId" },
        { data: "creationDate" },
        { data: "amount" },
        { data: "amountWithVAT" },
        { data: "action" }
      ],
      columnDefs: [
        { targets: 8, orderable: false } // action 'détail' && 'activer/desactiver'
      ]
    }

    new DataTable("#invoices-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (title === "Date") {
              createDateInput(this)
            } else {
              let inputStyle = null
              if (title === "N° client" || title === "N° dossier" || title === "N° facture" || title === "N° commande") inputStyle = "width: 100px;"
              createTextInput(this, inputStyle)
            }
            return true
          })
        // show filter
        $("#invoices-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  exposMonumentDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#expos-monuments-datatable").data("source")
      },
      columns: [
        { data: "client" },
        { data: "clientId" },
        { data: "folderId" },
        { data: "order_id" },
        { data: "serial_number" },
        { data: "status" },
        { data: "type" },
        { data: "buying_price_without_vat" },
        { data: "selling_price_with_vat" },
        { data: "discounted_selling_price" },
        { data: "sale_discount" },
        { data: "action" }
      ],
      columnDefs: [
        { targets: 11, orderable: false } // actions
      ]
    }

    const table = new DataTable("#expos-monuments-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (title === "Statut" || title === "Type") {
              let selectId
              if (title === "Statut") selectId = "#status"
              else if (title === "Type") selectId = "#type"
              createSelectInput(this, (select) => {
                document.querySelector(selectId).options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else {
              let inputStyle = null
              if (["N° client", "N° dossier", "N° serie", "N° commande", "Statut", "Type", "Prix achat (HT)", "Prix vente (TTC)", "Prix remisé", "Promo"].includes(title)) inputStyle = "width: 100px;"
              createTextInput(this, inputStyle)
            }
            return true
          })
        // show filter
        $("#expos-monuments-datatable tfoot").css("display", "table-header-group")
      }
    })

    const self = this
    table.on("draw", function (e, settings) {
      self.exportFn(e, settings)
    })
  }

  consignmentStockDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#consignment-stocks-datatable").data("source")
      },
      columns: [
        { data: "rep" },
        { data: "contact_name" },
        { data: "client" },
        { data: "monument" },
        { data: "ndossier" },
        { data: "nserie" },
        { data: "tarifAchat" },
        { data: "commande" },
        { data: "livraison" },
        { data: "echeance" },
        { data: "renouvele" },
        { data: "facturation" },
        { data: "action" }
      ],
      columnDefs: [
        { targets: 12, orderable: false } // action
      ]
    }

    const table = new DataTable("#consignment-stocks-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (title === "Client" || title === "Commercial") {
              let selectId
              if (title === "Client") selectId = "#client_id"
              if (title === "Commercial") selectId = "#contact_name"
              createSelectInput(this, (select) => {
                select.css("width", "150px")
                document.querySelector(selectId).options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else if (["Commandé", "Livraison", "Échéance", "Renouvelé", "Facturation"].includes(title)) {
              createDateInput(this)
            } else {
              let inputStyle = null
              if (["Client", "N° dossier", "N° série", "Tarif achat", "Commandé", "Livraison", "Échéance", "Renouvelé", "Facturation"].includes(title)) inputStyle = "width: 100px;"
              createTextInput(this, inputStyle)
            }
            return true
          })
        // show filter
        $("#consignment-stocks-datatable tfoot").css("display", "table-header-group")
      }
    })

    const self = this
    table.on("draw", function (e, settings) {
      self.exportFn(e, settings)
    })
  }

  stockDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#stocks-datatable").data("source")
      },
      columns: [
        { data: "prerendu" },
        { data: "categorie" },
        { data: "id" },
        { data: "nom" },
        { data: "granite" },
        { data: "granite_minor" },
        { data: "monument" },
        { data: "accessoire" },
        { data: "motif" },
        { data: "semelle" },
        { data: "quantites" }
      ]
    }

    new DataTable("#stocks-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (title === "Catégorie" || title === "Granite" || title === "Granit secondaire") {
              let selectId
              if (title === "Catégorie") selectId = "#category"
              if (title === "Granite") selectId = "#granite"
              if (title === "Granit secondaire") selectId = "#secondary_granite"
              createSelectInput(this, (select) => {
                document.querySelector(selectId).options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else {
              let inputStyle = null
              if (["Id", "Monument", "Accessoire", "Motif", "Semelle", "Quantités"].includes(title)) inputStyle = "width: 100px;"
              createTextInput(this, inputStyle)
            }
            return true
          })
        // show filter
        $("#stocks-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  catalogMonumentDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#catalog-monument-datatable").data("source")
      },
      columns: [
        { data: "reference" },
        { data: "order" },
        { data: "name" },
        { data: "tombale" },
        { data: "stele" },
        { data: "categorie" },
        { data: "disponible" },
        { data: "bicolore" },
        { data: "redirection" },
        { data: "catalogues" },
        { data: "config_famille" },
        { data: "vue" },
        { data: "tarif" },
        { data: "specifite" },
        { data: "action" }
      ],
      order: [[1, "asc"]],
      columnDefs: [
        { targets: 1, visible: false, orderable: false },
        { targets: 9, orderable: false },
        { targets: 10, orderable: false },
        { targets: 11, orderable: false },
        { targets: 12, orderable: false },
        { targets: 13, orderable: false },
        { targets: 14, orderable: false }
      ]
    }

    new DataTable("#catalog-monument-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (["Catégorie", "Tombale", "Stèle", "Disponible", "Bicolore", "Catalogues", "Config. Famille"].includes(title)) {
              let selectId
              switch (title) {
                case "Catégorie":
                  selectId = "#category"
                  break
                case "Tombale":
                  selectId = "#footstone"
                  break
                case "Stèle":
                  selectId = "#headstone"
                  break
                case "Disponible":
                  selectId = "#available"
                  break
                case "Bicolore":
                  selectId = "#bicolor"
                  break
                case "Catalogues":
                  selectId = "#catalog"
                  break
                case "Config. Famille":
                  selectId = "#family_category"
                  break
              }
              createSelectInput(this, (select) => {
                document.querySelector(selectId).options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else {
              let inputStyle = null
              if (["Référence", "Tombale", "Stèle", "Catégorie", "Disponible", "Bicolore", "Redirection"].includes(title)) inputStyle = "width: 100px;"
              createTextInput(this, inputStyle)
            }
            return true
          })
        // show filter
        $("#catalog-monument-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  monumentPriceDT() {
    let initialLoad = true
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#monument-price-datatable").data("source"),
      },
      drawCallback: function () {
        if (initialLoad) {
          setTimeout(() => {
            applyUrlParamsToFilters()
          }, 100)
          initialLoad = false
        }
      },
      columns: [
        { data: "reference" },
        { data: "configuration" },
        { data: "footstone" },
        { data: "headstone" },
        { data: "baseHeight" },
        { data: "granite" },
        { data: "secondaryGranite" },
        { data: "amount" },
        { data: "actions" }
      ],
      columnDefs: [
        { targets: 8, orderable: false }
      ]
    }

    new DataTable("#monument-price-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (["ID", "Recette", "Tombale", "Stèle", "Granit", "Granit Secondaire"].includes(title)) {
              let selectId
              switch (title) {
                case "ID":
                  selectId = "#ref"
                  break
                case "Recette":
                  selectId = "#layout"
                  break
                case "Tombale":
                  selectId = "#footstone"
                  break
                case "Stèle":
                  selectId = "#headstone"
                  break
                case "Granit":
                  selectId = "#granite"
                  break
                case "Granit Secondaire":
                  selectId = "#graniteSecondary"
                  break
              }
              createSelectInput(this, (select) => {
                document.querySelector(selectId).options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              }, { id: `${selectId.substring(1)}_select` })
            } else {
              let inputStyle = null
              if (["Référence", "Tombale", "Stèle", "Catégorie", "Disponible", "Bicolore", "Redirection"].includes(title)) inputStyle = "width: 100px;"
              createTextInput(this, inputStyle)
            }
            return true
          })
        // show filter
        $("#monument-price-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  accessoryPriceDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#accessory-price-datatable").data("source")
      },
      columns: [
        { data: "reference" },
        { data: "granite" },
        { data: "amount" },
        { data: "actions" }
      ],
      columnDefs: [
        { targets: 3, orderable: false }
      ]
    }

    new DataTable("#accessory-price-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (["Accessoire", "Granit"].includes(title)) {
              let selectId
              switch (title) {
                case "Accessoire":
                  selectId = "#accessory"
                  break
                case "Granit":
                  selectId = "#granite"
                  break
              }
              createSelectInput(this, (select) => {
                document.querySelector(selectId).options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else {
              createTextInput(this)
            }
            return true
          })
        // show filter
        $("#accessory-price-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  framePriceDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#frame-price-datatable").data("source")
      },
      columns: [
        { data: "configuration" },
        { data: "symmetry" },
        { data: "dimensions" },
        { data: "type" },
        { data: "height" },
        { data: "granite" },
        { data: "amount" },
        { data: "actions" }
      ],
      columnDefs: [
        { targets: 7, orderable: false }
      ]
    }

    new DataTable("#frame-price-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (["Recette", "Dimension", "Type", "Granit"].includes(title)) {
              let selectId
              switch (title) {
                case "Recette":
                  selectId = "#layout"
                  break
                case "Dimension":
                  selectId = "#dimensions"
                  break
                case "Type":
                  selectId = "#type"
                  break
                case "Granit":
                  selectId = "#granite"
                  break
              }
              createSelectInput(this, (select) => {
                document.querySelector(selectId).options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else {
              createTextInput(this)
            }
            return true
          })
        // show filter
        $("#frame-price-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  engravingPriceDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#engraving-price-datatable").data("source")
      },
      columns: [
        { data: "type" },
        { data: "color_name" },
        { data: "fontFamily" },
        { data: "fontSize" },
        { data: "amount" },
        { data: "actions" }
      ],
      columnDefs: [
        { targets: 5, orderable: false }
      ]
    }

    new DataTable("#engraving-price-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if (title === "") return true

            if (["Type", "Couleur", "Fonte", "Dimension"].includes(title)) {
              let selectId
              switch (title) {
                case "Type":
                  selectId = "#type"
                  break
                case "Couleur":
                  selectId = "#color"
                  break
                case "Fonte":
                  selectId = "#name"
                  break
                case "Dimension":
                  selectId = "#size"
                  break
              }
              createSelectInput(this, (select) => {
                document.querySelector(selectId).options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else {
              createTextInput(this)
            }
            return true
          })
        // show filter
        $("#engraving-price-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  sentMailsDT() {
    // Setting DataTable
    const opt = {
      ...defaultOptions,
      ajax: {
        url: $("#sent-mails-datatable").data("source")
      },
      columns: [
        { data: "id" },
        { data: "client_id" },
        { data: "uuid" },
        { data: "member_id" },
        { data: "quote" },
        { data: "configuration" },
        { data: "subject" },
        { data: "to" },
        { data: "status" },
        { data: "performed_at" },
        { data: "succeeded_at" },
        { data: "failed_at" },
        { data: "error_logs" },
        { data: "attachments" },
        { data: "outlook" },
        { data: "contenu" },
        { data: "traite" },
        { data: "resend" }
      ],
      order: [[0, "desc"]],
      columnDefs: [
        { targets: 17, orderable: false },
        { targets: 15, orderable: false },
        { targets: 14, orderable: false }
      ]
    }

    new DataTable("#sent-mails-datatable", { // eslint-disable-line no-new
      ...opt,
      initComplete: function () {
        this.api()
          .columns()
          .every(function () {
            const title = this.footer().textContent
            if ([""].includes(title)) return true

            if (["Statut", "Traité"].includes(title)) {
              let selectId
              switch (title) {
                case "Statut":
                  selectId = "#status"
                  break
                case "Traité":
                  selectId = "#handled"
                  break
              }
              createSelectInput(this, (select) => {
                document.querySelector(selectId).options.forEach(function (opt) {
                  if (!opt.value) return true
                  let $option = $("<option>").val(opt.value).text(opt.text)
                  select.append($option)
                })
              })
            } else if (["Effectué à", "Réussi à", "Échec à"].includes(title)) {
              createDateInput(this)
            } else {
              let inputStyle = null
              if (title === "#") inputStyle = "width: 50px;"
              if (["N° client", "ID Membre", "N° devis", "Configuration"].includes(title)) inputStyle = "width: 110px;"
              createTextInput(this, inputStyle)
            }
            return true
          })
        // show filter
        $("#sent-mails-datatable tfoot").css("display", "table-header-group")
      }
    })
  }

  // Set Event Listner to export BTN
  exportFn(event, settings, selector = "#export_btn") {
    const exportBtnElement = $(selector)
    exportBtnElement.off()
    exportBtnElement.on("click", function (e) {
      e.preventDefault()
      const xlsEndPoint = e.currentTarget.href
      const paramsObj = settings.api.ajax.params() // params from dataTable
      const paramsQuery = buildQuery(paramsObj)

      window.location.href = `${xlsEndPoint}?${paramsQuery}`
    })
  }
}

// Define the escapeRegex function
function escapeRegex(value) {
  return value.replace(/[\^$\\.*+?()[\]{}|]/g, "\\$&")
}

function createSelectInput(column, fn, attrs = {}) {
  let footerCell = $(column.footer())
  const title = column.footer().textContent

  // Create a select element
  let select = $("<select><option value=''>" + title + "</option></select>")
    .appendTo(footerCell.empty())
    .on("change", function () {
      // Filter the column based on the select value
      let val = escapeRegex($(this).val())
      column.search(val ? `^${val}$` : "", true, false).draw()
    })

  $.each(attrs, function (key, value) {
    select.attr(key, value)
  })

  fn(select)
}

function createTextInput(column, style = null, type = "text") {
  const title = column.footer().textContent

  // Create input element
  let input = document.createElement("input")
  input.placeholder = title
  input.type = type
  if (style) input.style = style
  column.footer().replaceChildren(input)

  // Event listener for user input
  input.addEventListener("keyup", (e) => {
    if (column.search() !== e.currentTarget.value) {
      column.search(input.value).draw()
    }
  })
}

function createDateInput(column, style = null) {
  // Create input element
  let input = document.createElement("input")
  input.type = "date"
  if (style) input.style = style
  column.footer().replaceChildren(input)

  // Event listener for user input
  input.addEventListener("change", (e) => {
    if (column.search() !== e.currentTarget.value) {
      column.search(input.value).draw()
    }
  })
}

function buildQuery(obj, prefix = "") {
  const str = []
  for (const p in obj) {
    if (obj.prototype.hasOwnProperty.call(p)) {
      const k = prefix ? `${prefix}[${p}]` : p
      const v = obj[p]
      str.push((v !== null && typeof v === "object")
        ? buildQuery(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    }
  }
  return str.join("&")
}

// Reusable function for handling URL params and triggering events
function applyUrlParamsToFilters() {
  const urlParams = new URLSearchParams(window.location.search)

  urlParams.forEach((value, key) => {
    const selectId = `${key}_select`
    const selectElement = document.getElementById(selectId)
    if (selectElement) {
      selectElement.value = value
      const event = new Event("change", {
        bubbles: true,
        cancelable: true,
      })
      selectElement.dispatchEvent(event)
    }
  })
}
